import clsx from "clsx";
import { Link, useLocation } from "react-router-dom";
import { checkIsActive } from "../../../helpers";
import { useEffect, useState } from "react";
const contentVideoMenus = [
  { title: "Entries", link: "content/video/entries" },
  // { title: "Category", link: "content/video/category" },
  // { title: "Genre", link: "content/video/genre" },
  // { title: "Cast", link: "content/video/cast" },
  // { title: "Language", link: "content/video/language" },
  // { title: "Production Company", link: "content/video/production-company" },
];
const contentLiveStreamMenu = [
  // { title: "Entries", link: "content/livestream/entries" },
  // { title: "Library", link: "content/livestream/library" },
  // { title: "EPG", link: "content/livestream/epg" },
];
const contentPlaylistMenu = [
  { title: "Series", link: "content/playlist/series" },
  { title: "Season", link: "content/playlist/season" },
];
const contentLibraryMenu = [
  { title: "Entries", link: "content/library/entries" },
  // { title: "Video", link: "content/library/video" },
  // { title: "Audio", link: "content/library/audio" },
  // { title: "Subtitle", link: "content/library/subtitle" },
  // { title: "Asset Store", link: "content/library/asset-store" },
];
const contentEncodingMenu = [
  { title: "Profile", link: "content/encoding/profile" },
  { title: "Presets", link: "content/encoding/presets" },
];
const contentMonetizationMenu = [
  { title: "Profile", link: "content/monetization/profile" },
  { title: "Plans", link: "content/monetization/plans" }
];
const analyticsMenu = [
  { title: "Content", link: "analytics/analytics-content" },
  { title: "Customers", link: "analytics/audience" },
  // { title: "Monetization", link: "analytics/analytics-monetization" },
];
const notificationMenu = [
  { title: "Groups", link: "manage/notification/groups" },
  { title: "Messages", link: "manage/notification/messages" },
];
const UserManagementMenu = [
  { title: "Admins", link: "manage/usermanagement/admins" },
  { title: "Roles", link: "manage/usermanagement/roles" },
];

const LayoutMenu = [
  { title: "General", link: "content/layout/general-layout" },
  { title: "Kids", link: "content/layout/kids-layout" },
  { title: "Sub Layout", link: "content/layout/sub-layout" },
  { title: "Kids Sub Layout", link: "content/layout/kids-sub-layout" },
];

const MonetizationMenu = [
  { title: "Plans", link: "monetization/plans" },
  // { title: "Profile", link: "monetization/profile" },
  { title: "Transactions", link: "monetization/transaction" },
];
const LibararyMenu = [
  { title: "Entries", link: "content/library/entries" },
  // { title: "Video", link: "content/library/video" },
  // { title: "Audio", link: "content/library/audio" },
  // { title: "Subtitle", link: "content/library/subtitle" },
];
const contentSettingsMenu = [
  { title: "Production House", link: "/manage/setting-contents/production-company" },
  { title: "Genre", link: "/manage/setting-contents/genre" },
  { title: "Cast", link: "/manage/setting-contents/cast" },
  { title: "Language", link: "/manage/setting-contents/language" },
  { title: "Category", link: "/manage/setting-contents/category" },
]
interface Menu {
  title: string;
  link: string;
}
const SecondaryMenu = () => {
  const { pathname } = useLocation();
  const [secondaryMenu, setSecondaryMenu] = useState<Menu[]>([]);

  useEffect(() => {
    // if (pathname.includes("/content/video")) {
    //   setSecondaryMenu(contentVideoMenus);
    // }
      if (pathname.includes("/content/livestream")) {
      setSecondaryMenu(contentLiveStreamMenu);
    }
    //  else if (pathname.includes("/content/playlist")) {
    //   setSecondaryMenu(contentPlaylistMenu);
    // }
    //  else if (pathname.includes("/content/library")) {
    //   setSecondaryMenu(contentLibraryMenu);
    // } 
    else if (pathname.includes("/content/encoding")) {
      setSecondaryMenu(contentEncodingMenu);
    } else if (
      pathname.includes("content/monetization") &&
      !pathname.includes("/analytics")
    ) {
      setSecondaryMenu(contentMonetizationMenu);
    } else if (pathname.includes("/analytics")) {
      setSecondaryMenu(analyticsMenu);
    } else if (pathname.includes("/notification")) {
      setSecondaryMenu(notificationMenu);
    } else if (pathname.includes("/usermanagement")) {
      setSecondaryMenu(UserManagementMenu);
    } else if (pathname.includes("/monetization")) {
      setSecondaryMenu(MonetizationMenu);
    } else if (pathname.includes("/audio/layout")) {
      setSecondaryMenu([]);      
    } else if (pathname.includes("/layout")) {
      setSecondaryMenu(LayoutMenu);
    // }else if (pathname.includes("/library")) {
    //   setSecondaryMenu(LibararyMenu);
    } else if (pathname.includes("/setting-contents")) {
      setSecondaryMenu(contentSettingsMenu);
    }  else {
      setSecondaryMenu([]);
    }
  }, [pathname]);

  return (
    <div
      id="#kt_app_header_secondary_menu"
      style={{
        backgroundColor: "#ffffff",
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        boxShadow: "0px 10px 30px 0px rgba(82, 63, 105, 0.05)",
        // zIndex: "99",
        // position: "fixed",
        // top: "74px",
        // width: "100%",
      }}
    >
      <div className="app-container container">
        <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
          {secondaryMenu.length > 0 &&
            secondaryMenu.map((item, index) => (
              <li
                className="nav-item"
                key={item.title}
                style={{ fontWeight: "500" }}
              >
                <Link
                  to={item.link}
                  className={clsx(
                    `nav-link text-active-primary link-dark py-5 me-6`,
                    {
                      active: checkIsActive(pathname, item.link),
                    }
                  )}
                >
                  {item.title}
                </Link>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export { SecondaryMenu };
