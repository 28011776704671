import { useIntl } from "react-intl";
import { MenuItem } from "./MenuItem";
import { MenuInnerWithSub } from "./MenuInnerWithSub";
import { MegaMenu } from "./MegaMenu";
import { useAuth } from "../../../../../app/modules/auth";
import { useLocation } from "react-router-dom";
import { MenuInnerWithPrimaryMenu } from "./MenuInnerWithPrimaryMenu";

export function MenuInner() {
  const intl = useIntl();

  const location = useLocation();

  const {currentUser} = useAuth()

  return (
    <>
      {(location.pathname === "/dashboard" ||
        (location.pathname !== "/content" &&
          !location.pathname.includes("/content"))
          &&
          (location.pathname !== "/manage" &&
          !location.pathname.includes("/manage")) 
          &&
          (location.pathname !== "/audio" &&
          !location.pathname.includes("/audio"))
          &&
          (location.pathname !== "/settings" &&
          !location.pathname.includes("/settings")) 
          &&
        location.pathname !== '/customers/list' 
          )
           && (
        <>
          <MenuItem
            title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
            to="/dashboard"
          />
          <MenuItem
            title={intl.formatMessage({ id: "MENU.VIDEOS" })}
            to="/content"
          />
          <MenuItem
            title={intl.formatMessage({ id: "MENU.AUDIOS" })}
            to="/audio"
          />
          <MenuItem
            title={intl.formatMessage({ id: "MENU.ANALYTICS" })}
            to="/analytics"
          />
           <MenuItem
            title={intl.formatMessage({ id: "MENU.MONETIZATION" })}
            to="/monetization"
          />
          <MenuItem
            title={intl.formatMessage({ id: "MENU.MANAGE" })}
            to="/manage"
          />
          {currentUser?.userType === 'SUPERADMIN' && 
          <MenuItem
            title={'Settings'}
            to="/settings"
          />
          }
          {/* <MenuItem
            title={intl.formatMessage({ id: "MENU.LAYOUT" })}
            to="/layout"
          />
           <MenuItem
            title={intl.formatMessage({ id: "MENU.CUSTOMERS" })}
            to="/users"
          />
           <MenuItem
            title={intl.formatMessage({ id: "MENU.NOTIFICATIONS" })}
            to="/notification"
          />
           <MenuItem
            title={intl.formatMessage({ id: "MENU.USERS" })}
            to="/usermanagement"
          /> */}
        </>
      )}
      {(location.pathname === "/content" ||
        location.pathname.includes("/content")) && (
        <>
          <MenuInnerWithPrimaryMenu
            title="Content"
            to="/content"
            menuPlacement="bottom-start"
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to="/content/video" title="Video" />
            <MenuItem to="/content/playlist" title="Series" />
            <MenuItem to="/content/livestream" title="Live TV" /> 
            <MenuItem to="/content/library" title="Library" />
            <MenuItem to="/content/encoding" title="Encoding" />
            <MenuItem to="/content/layout/general-layout" title="Layout" /> 
            {/* <MenuItem
              title={intl.formatMessage({ id: "MENU.MONETIZATION" })}
              to="content/monetization"
            /> */}
          </MenuInnerWithPrimaryMenu>
        </>
      )}
       {(location.pathname === "/audio" ||
        location.pathname.includes("/audio")) && (
        <>
          <MenuInnerWithPrimaryMenu
            title="Audio"
            to="/audio"
            menuPlacement="bottom-start"
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to="/audio/albums" title="Albums" />
            <MenuItem to="/audio/podcast" title="Podcast" />
            <MenuItem to="/audio/playlist" title="Playlist" />
            <MenuItem to="/audio/genre" title="Genre" />
            <MenuItem to="/audio/layout" title="Layout" /> 
          </MenuInnerWithPrimaryMenu>
        </>
      )}
 {(location.pathname === "/manage" ||
        location.pathname.includes("/manage")) && (
        <>
          <MenuInnerWithPrimaryMenu
            title="Manage"
            to="/manage"
            menuPlacement="bottom-start"
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
             <MenuItem to="/manage/setting-contents" title="Content Settings" />
            <MenuItem to="/manage/users" title="User Management" />
            <MenuItem to="/manage/usermanagement" title="Admins" />
            <MenuItem to="/manage/notification" title="Broadcast" />
             {/* <MenuItem to="/manage/production-company" title="Production House" />
           <MenuItem to="/manage/users" title="Customers" />
            <MenuItem to="/manage/usermanagement" title="Admins" />
            <MenuItem to="/manage/layout" title="Layout" /> 
            <MenuItem to="/manage/notification" title="Notification" />
            <MenuItem to="/manage/feedback" title="Feedback" /> */}
          </MenuInnerWithPrimaryMenu>
        </>
      )}
       {(location.pathname === "/settings" ||
        location.pathname.includes("/settings")) && (
        <>
          <MenuInnerWithPrimaryMenu
            title="Settings"
            to="/settings"
            menuPlacement="bottom-start"
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
             <MenuItem to="/settings/firebase" title="Integration" />
           {/* <MenuItem to="/settings/customerconfig" title="Customer Config" />
            <MenuItem to="/settings/payment" title="Payment" /> */}
          </MenuInnerWithPrimaryMenu>
        </>
      )}
      {/* <MenuInnerWithSub
        title={intl.formatMessage({ id: "MENU.ANALYTICS" })}
        to="/analytics"
        menuPlacement="bottom-start"
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        <MenuItem
          to="/analytics/user-analytics"
          title={intl.formatMessage({ id: "MENU.ANALYTICS.USER_ANALYTICS" })}
          hasBullet={true}
        />
        <MenuItem
          to="/analytics/content-analytics"
          title={intl.formatMessage({ id: "MENU.ANALYTICS.CONTENT_ANALYTICS" })}
          hasBullet={true}
        />
      </MenuInnerWithSub>
      <MenuInnerWithSub
        title={intl.formatMessage({ id: "MENU.CONTENT_MANAGEMENT" })}
        to="/analytics"
        menuPlacement="bottom-start"
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        <MenuItem
          to="/analytics/user-analytics"
          title={intl.formatMessage({ id: "MENU.CONTENT_MANAGEMENT.VIDEOS" })}
          hasBullet={true}
        />
        <MenuItem
          to="/analytics/content-analytics"
          title={intl.formatMessage({ id: "MENU.CONTENT_MANAGEMENT.SERIES" })}
          hasBullet={true}
        />
        <MenuItem
          to="/analytics/content-analytics"
          title={intl.formatMessage({ id: "MENU.CONTENT_MANAGEMENT.LAYOUTS" })}
          hasBullet={true}
        />
      </MenuInnerWithSub>
      <MenuInnerWithSub
        title={intl.formatMessage({ id: "MENU.CONTENT_LIBRARY" })}
        to="/content-library"
        menuPlacement="bottom-start"
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        <MenuItem
          to="/content-library/video-library"
          title={intl.formatMessage({
            id: "MENU.CONTENT_LIBRARY.VIDEO_LIBRARY",
          })}
          hasBullet={true}
        />
        <MenuItem
          to="/content-library/audio-library"
          title={intl.formatMessage({
            id: "MENU.CONTENT_LIBRARY.AUDIO_LIBRARY",
          })}
          hasBullet={true}
        />
      </MenuInnerWithSub> */}
      {/* <MenuInnerWithSub
        title={intl.formatMessage({ id: "MENU.CONTENT_SETTINGS" })}
        to="/content-settings"
        menuPlacement="bottom-start"
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        <MenuItem
          to="/content-settings/genre"
          title={intl.formatMessage({ id: "MENU.CONTENT_SETTINGS.GENRE" })}
          hasBullet={true}
        />
        <MenuItem
          to="/content-settings/languages"
          title={intl.formatMessage({ id: "MENU.CONTENT_SETTINGS.LANGUAGES" })}
          hasBullet={true}
        />
        <MenuItem
          to="/content-settings/cast"
          title={intl.formatMessage({ id: "MENU.CONTENT_SETTINGS.CAST" })}
          hasBullet={true}
        />
        <MenuItem
          to="/content-settings/category"
          title={intl.formatMessage({ id: "MENU.CONTENT_SETTINGS.CATEGORY" })}
          hasBullet={true}
        />
      </MenuInnerWithSub>
      <MenuInnerWithSub
        title={intl.formatMessage({ id: "MENU.MONETIZATION" })}
        to="/monetization"
        menuPlacement="bottom-start"
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        <MenuItem
          to="/monetization/subscription"
          title={intl.formatMessage({
            id: "MENU.MONETIZATION.SUBSCRIPTION",
          })}
          hasBullet={true}
        />
        <MenuItem
          to="/monetization/transactions"
          title={intl.formatMessage({
            id: "MENU.MONETIZATION.TRANSACTIONS",
          })}
          hasBullet={true}
        />
      </MenuInnerWithSub>
      <MenuInnerWithSub
        title={intl.formatMessage({ id: "MENU.NOTIFICATIONS" })}
        to="/notifications"
        menuPlacement="bottom-start"
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        <MenuItem
          to="/notifications/app-version"
          title={intl.formatMessage({
            id: "MENU.NOTIFICATIONS.APP_VERSION",
          })}
          hasBullet={true}
        />
        <MenuItem
          to="/notifications/notification-groups"
          title={intl.formatMessage({
            id: "MENU.NOTIFICATIONS.NOTIFICATION_GROUPS",
          })}
          hasBullet={true}
        />
        <MenuItem
          to="/notifications/notifications"
          title={intl.formatMessage({
            id: "MENU.NOTIFICATIONS.NOTIFICATIONS",
          })}
          hasBullet={true}
        />
      </MenuInnerWithSub>
      <MenuInnerWithSub
        title={intl.formatMessage({ id: "MENU.SUPPORT" })}
        to="/support"
        menuPlacement="bottom-start"
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        <MenuItem
          to="/support/tickets"
          title={intl.formatMessage({
            id: "MENU.SUPPORT.TICKETS",
          })}
          hasBullet={true}
        />
      </MenuInnerWithSub>
      <MenuInnerWithSub
        title={intl.formatMessage({ id: "MENU.ADMIN" })}
        to="/admin"
        menuPlacement="bottom-start"
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        <MenuItem
          to="/admin/admin-users"
          title={intl.formatMessage({
            id: "MENU.ADMIN.ADMIN_USERS",
          })}
          hasBullet={true}
        />
        <MenuItem
          to="/admin/roles"
          title={intl.formatMessage({
            id: "MENU.ADMIN.ROLES",
          })}
          hasBullet={true}
        />
      </MenuInnerWithSub> */}
    </>
  );
}
